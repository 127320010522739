<template>
<div class="welcome-container">
    <TourialLogo />
    <div style="width: 100%;">
        <h3 class="bold">{{ $t('welcome.title') }}</h3>
        <p>{{ $t('welcome.caption') }}</p>
    </div>

    <vs-button size="large" block class="bold" @click="$router.push('/explore/login')">{{ $t('login.signIn').toUpperCase() }}</vs-button>
    <vs-button dark size="large" block class="bold" @click="$router.push('/explore/register')">{{ $t('register.signUp').toUpperCase() }}</vs-button>

    <p class="center">
        {{ $t('welcome.disclamer') }}
    </p>
    

    <p class="center">
        {{ $t('login.signInWith') }}
    </p>

    <vs-button transparent size="large" :loading="googleLoading" @click="signInWithGoogle"><img src="../../assets/icons/google.png" /></vs-button>

    <Footer />

</div>
</template>

<script>
import Footer from '../Footer.vue';
import TourialLogo from '../TourialLogo.vue';
import {
    baseUri
} from '../../utils/ApiMiddleware';
export default {
    name: 'welcome',
    props: {},
    components: {
        TourialLogo,
        Footer
    },

    data() {
        return {
            googleLoading: false,
        }
    },

    methods: {
        async signInWithGoogle() {
            this.googleLoading = true;
            setTimeout(() => {
                this.googleLoading = true;
                window.location.href = baseUri + '/auth/external?provider=google';
            }, 1000);
        }
    },
}
</script>

<style scoped>
.welcome-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: 0;
}

/* DESKTOP */
@media (min-width: 1024px) {
    .welcome-container {
        width: 350px;

    }
}

/* MOBILE */
@media (max-width: 600px) {
    .welcome-container {

        justify-content: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        padding-bottom: 80px !important;
        height: unset !important;
        width: 100% !important;

    }
}
</style>
